import React from "react"

import { Link } from 'gatsby'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import styled from 'styled-components'
import Contact from "../components/HomePage/Contact"
import { color1, color12 } from '../constants/colors'
import MaxWidthContainer from '../components/Layout/MaxWidthContainer'

const NotFoundContainer = styled.div`
 background-color: ${color1};
 height: calc(100% - 90px);
 margin: 0;
 padding-top: 20px;
 padding-bottom: 50px;
`
const NotFoundHeader = styled.h4`
 margin: 0;
 text-align: center;
 font-family: Roboto, sans-serif;
 font-size: 12px;
 letter-spacing: 1px;
 @media(min-width: 600px){
  font-size: 18px;
  padding-bottom: 15px;
 }
 @media(min-width: 1024px){
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 30px;
 }
 `

const NotFoundPage = () => (
  <Layout>
    <SEO title="Strony nie znaleziono" />
    <NotFoundContainer>
      <MaxWidthContainer>
        <NotFoundHeader>
          Strony nie znaleziono. <Link to="/">Kliknij</Link> aby wrócić do strony głównej
      </NotFoundHeader>
      </MaxWidthContainer>
    </NotFoundContainer>
    <Contact />
  </Layout>
)

export default NotFoundPage
